import { ReactElement, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import qs from 'query-string';

import styles from './AppLayout.module.scss';
import chevron from '@/assets/img/chevron.svg';
import 'react-toastify/dist/ReactToastify.css';

import { Paths } from '@/router';
import { RootState, useAppDispatch } from '@/store';
import AppCircularSpinner from '@/components/app/AppCircularSpinner';
import { setCompany, setError, setIdentity, setLoading } from '@/store/clientSlice';
import { ClientService } from '@/services';
import { setCssCustomProperty } from '@/utils/cssCustomProperties';
import { setI18 } from '@/store/i18nSlice';

const SHOW_BACK_BTN_PATHS: string[] = [Paths.confirmCode];

export default function AppLayout(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { subdomain } = useParams();

  //
  // Store
  //

  const dispatch = useAppDispatch();
  const { loading, identity } = useSelector((state: RootState) => state.client);

  //
  // Methods
  //

  const performMobileViewportHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  };

  const loadClientData = async () => {
    const slug = qs.parse(location.search).slug || identity?.slug || subdomain;

    if (!slug || typeof slug !== 'string' || Array.isArray(slug) || !subdomain) {
      return;
    }

    dispatch(setError(false));
    dispatch(setLoading(true));

    try {
      const identity = await ClientService.getClientIdentity(slug!, subdomain);
      const company = await ClientService.getCofixCompanyData();

      dispatch(setIdentity(identity));
      dispatch(setI18(identity));
      dispatch(setCompany(company));

      setCssCustomProperty('--accentColor', identity.accentColor);
      setCssCustomProperty('--backgroundColor', identity.backgroundColor);
      setCssCustomProperty('--textColor', identity.textColor);
      setCssCustomProperty('--textHeadingColor', identity.textHeadingColor);
      setCssCustomProperty('--buttonHoverColor', identity.buttonHoverColor);
      setCssCustomProperty('--buttonRippleColor', identity.buttonRippleColor);
      setCssCustomProperty('--buttonTextColor', identity.buttonTextColor);
      setCssCustomProperty('--dividerColor', identity.dividerColor);
      setCssCustomProperty('--headerBgColor', identity.headerBgColor);
      setCssCustomProperty('--inputBgColor', identity.inputBgColor);
    } catch (error) {
      dispatch(setError(true));
      navigate(`/404`);
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isNeedBackButton = () => {
      return SHOW_BACK_BTN_PATHS.some((sbbp) => location.pathname.includes(sbbp))
  }

  const isAuthPage = () => {
      return window?.localStorage.getItem('currentLanguage') && !location.state;
  }

  const handleGoBack = ()=>{
      if(isNeedBackButton()) {
          navigate(-1);
      } else {
          if(window) {
              window.localStorage.removeItem('currentLanguage');
              window.location.reload();
          }
      }
  }

  //
  // Effects
  //

  useEffect(() => {
    loadClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Navigate initially to the first page
  useEffect(() => {
    if (location.pathname.includes('card') && ClientService.getCacheTmpUserInfo()) {
      return;
    }

    navigate(`/${subdomain}${location.search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    performMobileViewportHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/favicon.png';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Render
  //

  const renderLoader = (
    <div className={styles.loader}>
      <AppCircularSpinner radius={60} stroke={3} />
    </div>
  );

  const renderBackButton = (isNeedBackButton() || isAuthPage()) ? (
    <div className={styles.arrow}>
      <img onClick={handleGoBack} src={chevron} alt="Стрелка назад" />
    </div>
  ) : (
    <div />
  );

  return (
    <section className={styles.host}>
      <header>
        <div className={styles.headerContent}>
          {renderBackButton}
          <h1>WALLET CARDS</h1>
          <div />
        </div>
      </header>
      <main>{loading ? renderLoader : <Outlet />}</main>
      <ToastContainer />
    </section>
  );
}
