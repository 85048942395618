type Document = {
  link: string;
  name_link: string;
};

export default class ClientIdentity {
    public logo: string | null;
    public name: string;
    public accentColor: string;
    public backgroundColor: string;
    public textColor: string;
    public textHeadingColor: string;
    public buttonHoverColor: string;
    public buttonRippleColor: string;
    public buttonTextColor: string;
    public dividerColor: string;
    public headerBgColor: string;
    public inputBgColor: string;
    public slug: string | null;
    public subdomain: string | null;
    public add_card_add: string;
    public add_card_almost_ready: string;
    public add_card_cannot_create_card: string;
    public add_card_connection_lost: string;
    public add_card_header: string;
    public add_card_press_the_button: string;
    public add_card_retry: string;
    public add_card_to_add_card: string;
    public auth_accepting_toc: string;
    public auth_confirm_checking: string;
    public auth_confirm_last_digits: string;
    public auth_confirm_retry_sent_code: string;
    public auth_confirm_time_remaining: string;
    public auth_confirm_will_call_or_sms: string;
    public auth_content_of_toc: string;
    public auth_login: string;
    public auth_registration: string;
    public auth_toc: string;
    public auth_your_phone: string;
    public common_accept: string;
    public common_refresh: string;
    public errors_code_invalid: string;
    public errors_code_request_limit_exceeded: string;
    public errors_connection_error: string;
    public errors_error: string;
    public errors_incorrect: string;
    public errors_input_error: string;
    public errors_min_two_letter: string;
    public errors_number_incorrect: string;
    public errors_over_years_old: string;
    public errors_required: string;
    public errors_smth_went_wrong: string;
    public errors_try_later: string;
    public form_birthdate: string;
    public form_dd: string;
    public form_female: string;
    public form_gender: string;
    public form_header: string;
    public form_male: string;
    public form_mm: string;
    public form_name: string;
    public form_other: string;
    public form_save: string;
    public form_surname: string;
    public form_yyyy: string;
    public documents: Document[];
    public language_text: string;
    public language_button: string;

    public constructor(data: Partial<ClientIdentity>) {
        this.logo = data.logo ?? null;
        this.name = data.name ?? '';
        this.accentColor = data.accentColor ?? '#d7ff00';
        this.backgroundColor = data.backgroundColor ?? '#fff';
        this.textColor = data.textColor ?? '#1a1a1a';
        this.textHeadingColor = data.textHeadingColor ?? '#1a1a1a';
        this.buttonHoverColor = data.buttonHoverColor ?? '#c5e416';
        this.buttonRippleColor = data.buttonRippleColor ?? '#f7ffcb';
        this.buttonTextColor = data.buttonTextColor ?? '#1a1a1a';
        this.dividerColor = data.dividerColor ?? '#e2e2e9';
        this.headerBgColor = data.headerBgColor ?? '#fff';
        this.inputBgColor = data.inputBgColor ?? '#fff';
        this.slug = data.slug ?? null;
        this.subdomain = data.subdomain ?? null;
        this.add_card_add = data.add_card_add ?? '';
        this.add_card_almost_ready = data.add_card_almost_ready ?? '';
        this.add_card_cannot_create_card = data.add_card_cannot_create_card ?? '';
        this.add_card_connection_lost = data.add_card_connection_lost ?? '';
        this.add_card_header = data.add_card_header ?? '';
        this.add_card_press_the_button = data.add_card_press_the_button ?? '';
        this.add_card_retry = data.add_card_retry ?? '';
        this.add_card_to_add_card = data.add_card_to_add_card ?? '';
        this.auth_accepting_toc = data.auth_accepting_toc ?? '';
        this.auth_confirm_checking = data.auth_confirm_checking ?? '';
        this.auth_confirm_last_digits = data.auth_confirm_last_digits ?? '';
        this.auth_confirm_retry_sent_code = data.auth_confirm_retry_sent_code ?? '';
        this.auth_confirm_time_remaining = data.auth_confirm_time_remaining ?? '';
        this.auth_confirm_will_call_or_sms = data.auth_confirm_will_call_or_sms ?? '';
        this.auth_content_of_toc = data.auth_content_of_toc ?? '';
        this.auth_login = data.auth_login ?? '';
        this.auth_registration = data.auth_registration ?? '';
        this.auth_toc = data.auth_toc ?? '';
        this.auth_your_phone = data.auth_your_phone ?? '';
        this.common_accept = data.common_accept ?? '';
        this.common_refresh = data.common_refresh ?? '';
        this.errors_code_invalid = data.errors_code_invalid ?? '';
        this.errors_code_request_limit_exceeded = data.errors_code_request_limit_exceeded ?? '';
        this.errors_connection_error = data.errors_connection_error ?? '';
        this.errors_error = data.errors_error ?? '';
        this.errors_incorrect = data.errors_incorrect ?? '';
        this.errors_input_error = data.errors_input_error ?? '';
        this.errors_min_two_letter = data.errors_min_two_letter ?? '';
        this.errors_number_incorrect = data.errors_number_incorrect ?? '';
        this.errors_over_years_old = data.errors_over_years_old ?? '';
        this.errors_required = data.errors_required ?? '';
        this.errors_smth_went_wrong = data.errors_smth_went_wrong ?? '';
        this.errors_try_later = data.errors_try_later ?? '';
        this.form_birthdate = data.form_birthdate ?? '';
        this.form_dd = data.form_dd ?? '';
        this.form_female = data.form_female ?? '';
        this.form_gender = data.form_gender ?? '';
        this.form_header = data.form_header ?? '';
        this.form_male = data.form_male ?? '';
        this.form_mm = data.form_mm ?? '';
        this.form_name = data.form_name ?? '';
        this.form_other = data.form_other ?? '';
        this.form_save = data.form_save ?? '';
        this.form_surname = data.form_surname ?? '';
        this.form_yyyy = data.form_yyyy ?? '';
        this.documents = data.documents ?? [];
        this.language_button = data.language_button ?? '';
        this.language_text = data.language_text ?? '';
    }
}
