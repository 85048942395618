import { FC } from 'react';
import styles from './CountryPickerPopup.module.scss';
import { CountryPickerPopupProps } from '@/components/CountryPickerPopup/CountryPickerPopup.type';

export const CountryPickerPopup: FC<CountryPickerPopupProps> = ({ onClick, checkIsActive, countries }) => {
    return (
            <div className={styles.countryPickerPopup}>
                {countries &&
                        countries.map((country) => {
                            const isActive = checkIsActive(country);

                            return (
                                    <div
                                            className={styles.countryPickerRow}
                                            key={country.code}
                                            onClick={() => {
                                                onClick(country);
                                            }}
                                    >
                                        <div>
                                            {country.flag && <img src={country.flag} />}
                                            <span>{country.name}</span>
                                        </div>

                                        {isActive ? (
                                                <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                    <rect width='30' height='30' rx='15' fill='#8FC056' />
                                                    <rect
                                                            width='2'
                                                            height='15'
                                                            transform='matrix(0.707107 0.707107 -0.701171 0.712993 22.3223 9)'
                                                            fill='white'
                                                    />
                                                    <rect
                                                            width='2'
                                                            height='9.99983'
                                                            transform='matrix(0.707107 -0.707107 0.712993 0.701171 6 14.0088)'
                                                            fill='white'
                                                    />
                                                </svg>
                                        ) : (
                                                <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                    <rect opacity='0.4' width='30' height='30' rx='15' fill='#888888' />
                                                </svg>
                                        )}
                                    </div>
                            );
                        })}
            </div>
    );
};

export default CountryPickerPopup;
