import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import {
  GatewayTimeout,
  NetworkError,
  ServerError,
  ServerUnavailable,
  InternalServerError,
  ValidationErrorResponse,
  NotFoundError,
} from './exceptions';

export function requestInterceptor(config: AxiosRequestConfig) {
  return config;
}

export function responseInterceptorSuccess(success: any) {
  return success;
}

export function responseInterceptorError(error: AxiosError<ValidationErrorResponse>) {
  if (error.code === 'ECONNABORTED' || error.code === 'ETIMEDOUT' || error.response?.status === 408) {
    return Promise.reject(new GatewayTimeout());
  }

  const { response } = error;

  if (axios.isCancel(error)) return Promise.reject(new Error('CANCELED'));

  if (!response) {
    return Promise.reject(new NetworkError(error.message));
  }

  let customError;

  switch (response.status) {
    case 404:
      customError = new NotFoundError();
      break;
    case 500:
      customError = new InternalServerError(response);
      break;
    case 502:
    case 503:
      customError = new ServerUnavailable();
      break;
    case 504:
      customError = new GatewayTimeout();
      break;
    default:
      customError = new ServerError(response);
  }

  return Promise.reject(customError);
}
