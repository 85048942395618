import { AuthAPI } from '@/api/sources';

const AuthService = {
  async getSmsCode(phone: string): Promise<void> {
    return AuthAPI.getSmsCode(phone);
  },

  async getCofixSmsCode(phone: string): Promise<void> {
    return AuthAPI.getCofixSmsCode(phone);
  },

  async verifyCode(phone: string, code: string): Promise<string> {
    return AuthAPI.verifyCode(phone, code);
  },
};

export default AuthService;
