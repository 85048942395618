import cn from 'classnames';

import styles from './CofixPreloader.module.scss';

import { useDebounce } from '@/hooks';
import CofixSpinner from '../CofixSpinner/CofixSpinner';

type Props = {
  loading: boolean;
};

export default function CofixPreloader(props: Props) {
  const { loading } = props;

  const loadingDebounced = useDebounce(loading, 1000);

  return (
    <div className={cn(styles.host, { [styles.hostOut]: !loadingDebounced })}>
      <div />

      <svg
        className={cn(styles.logo, { [styles.logoOut]: !loadingDebounced })}
        width="151"
        height="50"
        viewBox="0 0 151 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9987 20.6828C22.6932 20.6828 25.6785 22.2641 27.3256 25.4836H36.8421C34.4859 17.9069 28.0119 12.7192 19.1131 12.7192C5.97069 12.7192 0 22.9581 0 31.1036C0 39.8294 6.22232 49.9999 19.4333 49.9999C28.1949 49.9999 34.2342 44.8805 36.8421 37.4858H27.3256C24.9694 41.7861 20.4628 42.0364 18.9987 42.0364C12.5133 42.0364 8.45275 37.2355 8.45275 31.2288C8.46419 25.6088 12.2731 20.6828 18.9987 20.6828Z"
          fill="white"
        />
        <path
          d="M58.3275 12.7192C47.4285 12.7192 39.4736 21.1833 39.4736 31.4222C39.4736 41.5927 47.4978 49.9999 58.3391 49.9999C69.1688 49.9999 77.1929 41.6041 77.1929 31.4222H77.1814C77.1814 21.1947 69.2381 12.7192 58.3275 12.7192ZM58.3391 42.0478C52.0583 42.0478 48.0173 37.1218 48.0173 31.4335C48.0173 25.7453 51.989 20.6941 58.3391 20.6941C64.7007 20.6941 68.6608 25.7567 68.6608 31.4335C68.6608 37.1104 64.6314 42.0478 58.3391 42.0478Z"
          fill="white"
        />
        <path
          d="M82.8523 12.2548V13.7493H79.386V21.2793H82.8523V49.1228H91.2124V21.2793H96.4913V13.7493H91.2011V12.2548C91.2011 10.4614 92.1753 8.48412 95.4151 8.48412H96.4799V0H94.9053C87.7574 0 82.8523 4.33403 82.8523 12.2548Z"
          fill="white"
        />
        <path d="M110.526 0H101.754V8.33334H110.526V0Z" fill="white" />
        <path d="M110.526 13.5966H101.754V49.1229H110.526V13.5966Z" fill="white" />
        <path
          d="M137.497 30.9037L149.69 13.5966H139.514L131.954 24.6574L124.383 13.5966H114.195L126.123 30.9037L113.158 49.1229H123.956L132.023 37.1961L140.09 49.1229H150.877L137.497 30.9037Z"
          fill="white"
        />
      </svg>

      <CofixSpinner loading={loadingDebounced} />
    </div>
  );
}
