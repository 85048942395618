import { AxiosResponse } from 'axios';

export type ValidationErrorResponse = {
  success: boolean;
  errors: string[];
  error_code: string;
};

/**
 * HTTP 503 - Typically, when application is updated, restarted, etc.
 */
export class ServerUnavailable extends Error {
  constructor() {
    super('Server is temporary unavailable, try in a few minutes');
    this.name = 'ServerUnavailable';
  }
}

/**
 * HTTP 500 - Most probably, unhandled error in server code.
 */
export class InternalServerError extends Error {
  constructor(response: AxiosResponse<ValidationErrorResponse>) {
    const message = response.data?.error_code ? response.data.error_code : response.statusText;
    super(message);
    this.name = 'InternalServerError';
  }
}

export class NotFoundError extends Error {
  constructor() {
    super('Resource not found');
    this.name = 'NotFoundError';
  }
}

/**
 * Server code was executing too long, and Nginx returned error without waiting for result.
 */
export class GatewayTimeout extends Error {
  constructor() {
    super('Server operation timed out');
    this.name = 'GatewayTimeout';
  }
}

/**
 * For all other cases, when server replies with error.
 */
export class ServerError extends Error {
  public data: any;

  constructor(response: AxiosResponse<ValidationErrorResponse>) {
    const message = response.data?.error_code ? response.data.error_code : response.statusText;
    super(message);
    this.name = 'ServerError';
    this.data = response.data;
  }
}

/**
 * HTTP Server reply was not received.
 * Probably no network connection or CORS error, AdBlock, etc.
 */
export class NetworkError extends Error {
  constructor(message: string) {
    super(message || 'Check your network connection');
    this.name = 'NetworkError';
  }
}
