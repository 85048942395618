import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import styles from './CofixConfirmCodePage.module.scss';

import { useCountdown } from '@/hooks';
import { AppButton } from '@/components/app';
import { AuthService, ClientService } from '@/services';
import { Paths } from '@/router';
import { RootState, useAppDispatch } from '@/store';
import { setCardAssets } from '@/store/clientSlice';
import { GatewayTimeout, InternalServerError, NotFoundError, ServerUnavailable } from '@/api/exceptions';
import wellKnownErrors from '@/api/wellKnownErrors';
import CofixErrorPopup, { ErrorPopupCfg } from '../computed/CofixErrorPopup';
import { ClientAPI } from '@/api/sources';
import { useSelector } from 'react-redux';

export default function CofixConfirmCodePage(): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const routerState = location.state as { phone: string; deformatPhone: string };
    const [countdown, { start: launchCountdown }] = useCountdown(59 * 1000);
    const codeRef = useRef<HTMLInputElement>(null);
    const { i18 } = useSelector((state: RootState) => state.i18n);

    //
    // State
    //

    const [code, setCode] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<ErrorPopupCfg>({
        open: false,
        type: '',
        message: '',
        btnType: 'close',
    });

    //
    // Store
    //

    const dispatch = useAppDispatch();

    //
    // Computed
    //

    const phone = routerState.phone ?? '';
    const deformatPhone = routerState.deformatPhone ?? '';
    const formValid = useMemo(() => code.length === 4 && deformatPhone, [code.length, deformatPhone]);

    //
    // Methods
    //

    function formatTimerSeconds(n: number) {
        return n > 9 ? '' + n : '0' + n;
    }

    const handleSubmit = async () => {
        if (!formValid) return;

        let token = null;

        try {
            setProcessing(true);
            token = await AuthService.verifyCode(deformatPhone, code);
        } catch (error) {
            const errorPopupCfg: ErrorPopupCfg = {
                open: true,
                type: i18?.errors_error ?? '',
                message: i18?.errors_code_invalid ?? '',
                btnType: 'close',
            };

            if (
                    !navigator.onLine ||
                    error instanceof ServerUnavailable ||
                    error instanceof InternalServerError ||
                    error instanceof GatewayTimeout
            ) {
                errorPopupCfg.type = i18?.errors_connection_error ?? '';
                errorPopupCfg.message = i18?.errors_try_later ?? '';
                errorPopupCfg.btnType = 'refresh';
            }

            setErrorPopup(errorPopupCfg);
            throw error;
        } finally {
            setProcessing(false);
        }

        try {
            setProcessing(true);
            const userInfo = await ClientAPI.getCofixUserInfo(deformatPhone, token);
            ClientService.setCacheTmpUserInfo({ phone: deformatPhone, token });

            if (userInfo) {
                const cardAssets = await ClientAPI.getCofixWalletAssets(deformatPhone, userInfo, token);
                dispatch(setCardAssets(cardAssets));
                navigate(`/${Paths.addCard}`);
            }
        } catch (error: any) {
            if (error instanceof NotFoundError) {
                // if (NOT REGISTERED)
                navigate(`/${Paths.form}`, { state: { deformatPhone, token } });
            }

            setErrorPopup({
                open: true,
                type: i18?.errors_connection_error ?? '',
                message: i18?.errors_try_later ?? '',
                btnType: 'refresh',
            });

            throw error;
        } finally {
            setProcessing(false);
        }
    };

    const resendCode = async () => {
        setProcessing(true);

        try {
            await AuthService.getCofixSmsCode(deformatPhone);
            launchCountdown();
        } catch (error: any) {
            const errorPopupCfg: ErrorPopupCfg = {
                open: true,
                type: i18?.errors_error ?? '',
                message: i18?.errors_smth_went_wrong ?? '',
                btnType: 'refresh',
            };

            if (
                    !navigator.onLine ||
                    error instanceof ServerUnavailable ||
                    error instanceof InternalServerError ||
                    error instanceof GatewayTimeout
            ) {
                errorPopupCfg.type = i18?.errors_connection_error ?? '';
                errorPopupCfg.message = i18?.errors_try_later ?? '';
                errorPopupCfg.btnType = 'refresh';
            } else if (error.data.error_code.includes(wellKnownErrors.validatePhoneError)) {
                errorPopupCfg.type = i18?.errors_input_error ?? '';
                errorPopupCfg.message = i18?.errors_number_incorrect ?? '';
                errorPopupCfg.btnType = 'close';
            } else if (
                    error.data.error_code.includes(wellKnownErrors.limit15MinutesRequestError) ||
                    error.data.error_code.includes(wellKnownErrors.limitDayRequestError)
            ) {
                errorPopupCfg.type = i18?.errors_error ?? '';
                errorPopupCfg.message = i18?.errors_code_request_limit_exceeded ?? '';
                errorPopupCfg.btnType = 'close';
            }

            setErrorPopup(errorPopupCfg);
            throw error;
        } finally {
            setProcessing(false);
        }
    };

    //
    // Effects
    //

    useEffect(() => {
        launchCountdown();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formValid && codeRef.current) {
            codeRef.current.blur();
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValid, codeRef]);

    //
    // Render
    //

    return (
            <section className={cn(styles.host, 'fadeIn')}>
                <header>
                    <svg
                            onClick={() => navigate(-1)}
                            width='12'
                            height='21'
                            viewBox='0 0 12 21'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M12 1.4L10.5882 0L1.4118 9.10003L1.41175 9.09999L0 10.5L10.5881 21L11.9999 19.6L2.82355 10.5L12 1.4Z'
                                fill='white'
                        />
                    </svg>
                    <h1>{i18?.auth_confirm_checking}</h1>
                    <span />
                </header>

                <form>
                    <label>
                        <span>{i18?.auth_confirm_last_digits}</span>
                        <input
                                ref={codeRef}
                                type='tel'
                                maxLength={4}
                                name='code'
                                disabled={processing}
                                value={code}
                                autoFocus
                                onChange={(e) => setCode(e.target.value)}
                        />
                    </label>

                    <p className={styles.message}>
                        {i18?.auth_confirm_will_call_or_sms} <span>{phone}</span> <br />
                    </p>
                    {countdown > 0 ? (
                            <p className={styles.timer}>
                                <span>{i18?.auth_confirm_time_remaining}</span> <br /> 0:{formatTimerSeconds(countdown / 1000)}
                            </p>
                    ) : (
                            <AppButton
                                    className={cn(styles.resendCodeBtn, 'fadeIn')}
                                    disabled={processing}
                                    ripple={false}
                                    onClick={() => {
                                        setCode('');
                                        resendCode();
                                    }}
                                    type='button'
                            >
                                {i18?.auth_confirm_retry_sent_code}
                            </AppButton>
                    )}
                </form>

                <CofixErrorPopup
                        btnAction={errorPopup.btnType}
                        message={errorPopup.message}
                        onChange={(open, message, type, btnType) => setErrorPopup({ open, message, type, btnType })}
                        open={errorPopup.open}
                        type={errorPopup.type}
                />
            </section>
    );
}
