import { AxiosResponse } from 'axios';

import { httpClient } from '../httpClient';
import { store } from '@/store';
import { AppFlavor } from '@/flavors';

const AuthAPI = {
  async getSmsCode(phone: string): Promise<void> {
    type Params = {
      phone: string;
    };

    const { data } = await httpClient.post<Params, AxiosResponse<any>>(
      '/api/v01/sms/request-code/',
      {
        phone,
      },
      {
        headers: {
          Subdomain: store.getState().client.identity?.subdomain || '',
        },
      }
    );

    return data;
  },

  async getCofixSmsCode(phone: string): Promise<void> {
    type Params = {
      phone: string;
    };

    const { data } = await httpClient.post<Params, AxiosResponse<any>>('/api/v01/sms/request-code/', {
      phone,
    });

    return data;
  },

  async verifyCode(phone: string, code: string): Promise<string> {
    type Params = {
      phone: string;
    };

    const { data } = await httpClient.post<Params, AxiosResponse<any>>(
      '/api/v01/sms/verify-code/',
      {
        phone,
        code,
      },
      {
        headers: {
          Subdomain: store.getState().client.identity?.subdomain || AppFlavor.subdomain,
        },
      }
    );

    return data.token;
  },
};

export default AuthAPI;
