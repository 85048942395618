import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type Actions = {
  pause: () => void;
  reset: () => void;
  resume: () => void;
  start: (timeToCount?: number) => void;
};

/**
 * mSeconds countdown.
 * @param timeToCount - mSeconds to count down.
 * @param interval - Update interval.
 * @returns [timeLeft, {pause, reset, resume, start}]
 */
const useCountdown = (timeToCount: number = 119 * 1000, interval: number = 1000): [number, Actions] => {
  const timer = useRef<any>({});

  //
  // State
  //

  const [timeLeft, setTimeLeft] = useState<number>(0);

  //
  // Methods
  //

  const run = useCallback(
    (ts) => {
      if (!timer.current.started) {
        timer.current.started = ts;
        timer.current.lastInterval = ts;
      }

      const localInterval = Math.min(interval, timer.current.timeLeft || Infinity);

      if (ts - timer.current.lastInterval >= localInterval) {
        timer.current.lastInterval += localInterval;
        setTimeLeft((tL: number) => {
          timer.current.timeLeft = tL - localInterval;
          return timer.current.timeLeft;
        });
      }

      if (ts - timer.current.started < timer.current.timeToCount) {
        timer.current.requestId = window.requestAnimationFrame(run);
      } else {
        timer.current = {};
        setTimeLeft(0);
      }
    },
    [interval]
  );

  const start = useCallback(
    (ttc?: number) => {
      window.cancelAnimationFrame(timer.current.requestId);

      const newTimeToCount = ttc !== undefined ? ttc : timeToCount;
      timer.current.started = null;
      timer.current.lastInterval = null;
      timer.current.timeToCount = newTimeToCount;
      timer.current.requestId = window.requestAnimationFrame(run);

      setTimeLeft(newTimeToCount);
    },
    [run, timeToCount]
  );

  const pause = useCallback(() => {
    window.cancelAnimationFrame(timer.current.requestId);
    timer.current.started = null;
    timer.current.lastInterval = null;
    timer.current.timeToCount = timer.current.timeLeft;
  }, []);

  const resume = useCallback(() => {
    if (!timer.current.started && timer.current.timeLeft > 0) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current.requestId = window.requestAnimationFrame(run);
    }
  }, [run]);

  const reset = useCallback(() => {
    if (timer.current.timeLeft) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current = {};
      setTimeLeft(0);
    }
  }, []);

  //
  // Computed
  //

  const actions = useMemo(() => ({ start, pause, resume, reset }), [pause, reset, resume, start]);

  //
  // Effects
  //

  useEffect(() => reset, [reset]);

  return [timeLeft, actions];
};

export default useCountdown;
