import {ClientAPI} from '@/api/sources';
import {ClientIdentity, CofixCompany} from '@/models';
import {ClientIdentityDto} from '@/models/dto';

type CacheTmpUserInfo = {
    phone: string;
    token: string;
};

const ClientService = {

    async getClientIdentity(slug: string, subdomain: string): Promise<ClientIdentity> {
        const dto = await ClientAPI.getClientIdentity(slug);

        return this._mapDtoToClientIdentity(dto, slug, subdomain);
    },

    async getCofixCompanyData(): Promise<CofixCompany> {
        const dto = await ClientAPI.getCofixCompanyData();

        return new CofixCompany({
            allowLanguage: dto.allow_language,
            countries: dto.country
                .map((cDto) => ({
                    code: cDto.code,
                    flag: cDto.flag || null,
                    maskPhone: cDto.mask_phone ? `${cDto.phone_code} ${cDto.mask_phone.split('#').join('9')}` : '',
                    name: cDto.name,
                    phoneCode: cDto.phone_code,
                }))
                .slice()
                .sort((a, b) => (a.name === 'Россия' ? -1 : 1)),
            defaultLanguage: {
                code: dto.default_language.code,
                name: dto.default_language.name,
                flag: dto.default_language.flag,
            },
            layoutCards: dto.layout_cards.map((lDto) => ({
                brand_name: lDto.brand_name,
                kind: lDto.kind,
                logo: lDto.logo,
                uuid: lDto.uuid,
            })),
            supportPhoneNumber: dto.support_phone_number,
            title: dto.title,
            website: dto.website,
        });
    },

    getCacheTmpUserInfo(): CacheTmpUserInfo | null {
        const cacheTmpUserInfo = localStorage.getItem('cacheTmpUserInfo');

        if (!cacheTmpUserInfo) return null;

        return JSON.parse(cacheTmpUserInfo);
    },

    removeCacheTmpUserInfo(): void {
        localStorage.removeItem('cacheTmpUserInfo');
    },

    setCacheTmpUserInfo(data: CacheTmpUserInfo): void {
        localStorage.setItem('cacheTmpUserInfo', JSON.stringify(data));
    },

    _mapDtoToClientIdentity(dto: ClientIdentityDto, slug: string, subdomain: string): ClientIdentity {
        return new ClientIdentity({
            slug,
            subdomain,
            name: dto.brand_name,
            logo: dto.logo,
            accentColor: dto.accent_color,
            backgroundColor: dto.background_color,
            textColor: dto.text_color,
            textHeadingColor: dto.text_heading_color,
            buttonHoverColor: dto.button_hover_color,
            buttonRippleColor: dto.button_ripple_color,
            buttonTextColor: dto.button_text_color,
            dividerColor: dto.divider_color,
            headerBgColor: dto.header_color,
            inputBgColor: dto.text_background_color,
            add_card_add: dto.add_card_add,
            add_card_almost_ready: dto.add_card_almost_ready,
            add_card_cannot_create_card: dto.add_card_cannot_create_card,
            add_card_connection_lost: dto.add_card_connection_lost,
            add_card_header: dto.add_card_header,
            add_card_press_the_button: dto.add_card_press_the_button,
            add_card_retry: dto.add_card_retry,
            add_card_to_add_card: dto.add_card_to_add_card,
            auth_accepting_toc: dto.auth_accepting_toc,
            auth_confirm_checking: dto.auth_confirm_checking,
            auth_confirm_last_digits: dto.auth_confirm_last_digits,
            auth_confirm_retry_sent_code: dto.auth_confirm_retry_sent_code,
            auth_confirm_time_remaining: dto.auth_confirm_time_remaining,
            auth_confirm_will_call_or_sms: dto.auth_confirm_will_call_or_sms,
            auth_content_of_toc: dto.auth_content_of_toc,
            auth_login: dto.auth_login,
            auth_registration: dto.auth_registration,
            auth_toc: dto.auth_toc,
            auth_your_phone: dto.auth_your_phone,
            common_accept: dto.common_accept,
            common_refresh: dto.common_refresh,
            errors_code_invalid: dto.errors_code_invalid,
            errors_code_request_limit_exceeded: dto.errors_code_request_limit_exceeded,
            errors_connection_error: dto.errors_connection_error,
            errors_error: dto.errors_error,
            errors_incorrect: dto.errors_incorrect,
            errors_input_error: dto.errors_input_error,
            errors_min_two_letter: dto.errors_min_two_letter,
            errors_number_incorrect: dto.errors_number_incorrect,
            errors_over_years_old: dto.errors_over_years_old,
            errors_required: dto.errors_required,
            errors_smth_went_wrong: dto.errors_smth_went_wrong,
            errors_try_later: dto.errors_try_later,
            form_birthdate: dto.form_birthdate,
            form_dd: dto.form_dd,
            form_female: dto.form_female,
            form_gender: dto.form_gender,
            form_header: dto.form_header,
            form_male: dto.form_male,
            form_mm: dto.form_mm,
            form_name: dto.form_name,
            form_other: dto.form_other,
            form_save: dto.form_save,
            form_surname: dto.form_surname,
            form_yyyy: dto.form_yyyy,
            documents: dto.documents?.map((dDto) => ({
                link: dDto.link,
                name_link: dDto.name_link,
            })),
            language_text: dto.language_text,
            language_button: dto.language_button,
        });
    },
};

export default ClientService;
