import { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import styles from './AppBottomSheet.module.scss';

interface Props {
  children?: ReactElement | string | ReactElement[] | JSX.Element[];
  isOpen: boolean;
  maxHeight?: number;
  adjustHeight?: boolean;
  onChangeState(open: boolean): void;
  className?: string;
  overscrollColor?: string;
  showStick?: boolean;
  adjustHeightDetectionDeps?: any;
}

function AppBottomSheet(props: Props): ReactElement {
  const {
    children,
    isOpen,
    onChangeState,
    maxHeight,
    className = '',
    adjustHeight = false,
    showStick = true,
    overscrollColor,
    adjustHeightDetectionDeps,
  } = props;

  //
  // State
  //

  const [hostHeight, setHostHeight] = useState<number>(0);
  const [hostNode, setHostNode] = useState<HTMLElement | null>(null);
  const [hostHeightChangeDetection, setHostHeightChangeDetection] = useState<boolean>(false);

  //
  // Effects
  //

  useEffect(() => {
    if (!overscrollColor) return;

    setTimeout(() => {
      const overscrollNode = document.querySelector<HTMLElement>('[data-rsbs-scroll]');

      if (overscrollNode) {
        overscrollNode.style.backgroundColor = overscrollColor;
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overscrollColor, isOpen, children]);

  useEffect(() => {
    if (!isOpen) return;

    if (showStick) {
      document.querySelector('body')?.classList.add('showStick');
    } else {
      document.querySelector('body')?.classList.remove('showStick');
    }
  }, [isOpen, showStick]);

  useEffect(() => {
    if (adjustHeight && hostNode && hostHeightChangeDetection) {
      setHostHeight(hostNode.clientHeight);
    }
  }, [adjustHeight, adjustHeightDetectionDeps, hostHeightChangeDetection, hostNode]);

  //
  // Methods
  //

  const onRefChange = useCallback((node) => {
    setHostNode(node);

    if (node !== null) {
      setTimeout(() => {
        setHostHeight(node.clientHeight || 0);
        setHostHeightChangeDetection(true);
      }, 0);
    }
  }, []);

  //
  // Render
  //

  return (
    <BottomSheet
      expandOnContentDrag
      header={false}
      onDismiss={() => onChangeState(false)}
      open={isOpen}
      snapPoints={
        adjustHeight && hostHeight < window.innerHeight - 90
          ? undefined
          : ({ maxHeight: availableMaxHeight }) => [maxHeight || availableMaxHeight]
      }
    >
      <div className={cn(styles.host, className)} key={isOpen.toString()} ref={onRefChange}>
        {children}
      </div>
    </BottomSheet>
  );
}

export default memo(AppBottomSheet);
