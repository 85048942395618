export type Country = {
  code: string;
  flag: string | null;
  maskPhone: string;
  name: string;
  phoneCode: string;
};

export type CofixCompanyLanguage = {
    code: string;
    name: string;
    flag: string;
};

type LayoutCard = {
  brand_name: string;
  kind: string;
  logo: string;
  uuid: string;
};

export default class CofixCompany {
  public allowLanguage: CofixCompanyLanguage[];
  public countries: Country[];
  public defaultLanguage: CofixCompanyLanguage | null;

  public layoutCards: LayoutCard[];
  public supportPhoneNumber: string;
  public title: string;
  public website: string | null;

  public constructor(data: Partial<CofixCompany>) {
    this.allowLanguage = data.allowLanguage ?? [];
    this.countries = data.countries ?? [];
    this.defaultLanguage = data.defaultLanguage ?? null;

    this.layoutCards = data.layoutCards ?? [];
    this.supportPhoneNumber = data.supportPhoneNumber ?? '';
    this.title = data.title ?? '';
    this.website = data.website ?? null;
  }
}
