import { forwardRef, LegacyRef, ReactElement, useState, CSSProperties } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import styles from './AppButton.module.scss';

interface Props {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  bg?: 'primary' | 'light';
  style?: CSSProperties;
  className?: string;
  ripple?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | null;
  id?: string;
  disabled?: boolean;
  disabledLight?: boolean;
}

function AppButton(props: Props, ref: LegacyRef<HTMLButtonElement>): ReactElement {
  const {
    children,
    type = 'button',
    bg = 'primary',
    style,
    className = '',
    ripple = true,
    onClick,
    id,
    disabled = false,
    disabledLight = false,
  } = props;

  //
  // State
  //

  const [rippleElements, setRippleElements] = useState<JSX.Element[]>([]);

  //
  // Methods
  //

  const onAnimationEnd = (key: string) => {
    setRippleElements((ri) => ri.filter((element) => element.key !== key));
  };

  const getWewRippleElement = (d: number, left: string, top: string) => {
    const key = uuid();

    return (
      <div
        className={cn(styles.ripple, { [styles.primaryRipple]: bg === 'primary' })}
        id={id}
        key={key}
        onAnimationEnd={() => onAnimationEnd(key)}
        style={{ width: d, height: d, left, top }}
      />
    );
  };

  const onRippleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const d = Math.max(event.currentTarget.clientWidth, event.currentTarget.clientHeight);
    const left = event.clientX - rect.left - d / 2 + 'px';
    const top = event.clientY - rect.top - d / 2 + 'px';
    const rippleElement = getWewRippleElement(d, left, top);

    setRippleElements([...rippleElements, rippleElement]);
  };

  return (
    <button
      className={cn(className, styles.host, {
        [styles.light]: bg === 'light',
        [styles.withRipple]: ripple,
        [styles.disabledLight]: disabledLight,
        [styles.primary]: bg === 'primary',
      })}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();

        if (onClick) {
          onClick(event);
        }

        if (ripple) {
          onRippleClick(event);
        }
      }}
      ref={ref}
      style={style}
      type={type}
    >
      {children}
      {rippleElements}
    </button>
  );
}

export default forwardRef(AppButton);
