import {ClientIdentityDto, CofixCompanyDto, UserInfoDto} from '@/models/dto';
import {AxiosResponse} from 'axios';
import {isIOS} from 'react-device-detect';

import {httpClient} from '../httpClient';
import {AppFlavor} from '@/flavors';
import {store} from '@/store';

const getCurrentLanguage = ()=>{
    const currentLanguage = window.localStorage.getItem('currentLanguage');
    return currentLanguage && currentLanguage !== 'ru' ? '/' + currentLanguage : ''
}
const ClientAPI = {

    async getClientIdentity(companyId: string): Promise<ClientIdentityDto> {
        type Params = {};

        const currentLanguage = window.localStorage.getItem('currentLanguage');

        const {data} = await httpClient.get<Params, AxiosResponse<any>>(`${currentLanguage && currentLanguage !== 'ru' ? '/' + currentLanguage : ''}/api/v01/layout-card/web-design/${companyId}/`);

        return data;
    },

    async getCofixWalletAssets(phone: string, userInfo: UserInfoDto, token: string): Promise<string | Blob | null> {
        type Params = {
            phone: string;
            code: string;
        };

        const {data} = await httpClient.post<Params, AxiosResponse<any>>(
            `${getCurrentLanguage()}/api/v02/cards/create-by-qr/apple/sailplay/`,
            {
                phone,
                first_name: userInfo.first_name || undefined,
                last_name: userInfo.last_name || undefined,
                birth_date: userInfo.birth_date || undefined,
                sex: userInfo.sex || undefined,
                layout_card_slug: AppFlavor.layoutCardSlug,
            },
            {
                responseType: isIOS ? 'blob' : 'json',
                headers: {
                    Authorization: token,
                },
            },
        );

        if (data instanceof Blob) return data;
        else if (data.apple_card) return data.apple_card;

        return null;
    },

    async getWalletAssets(phone: string, userInfo: UserInfoDto, token: string): Promise<string | Blob | null> {
        type Params = {
            phone: string;
            code: string;
        };

        const {data} = await httpClient.post<Params, AxiosResponse<any>>(
            `${getCurrentLanguage()}/api/v02/cards/create-by-qr/apple/sailplay/`,
            {
                phone,
                first_name: userInfo.first_name || undefined,
                last_name: userInfo.last_name || undefined,
                birth_date: userInfo.birth_date || undefined,
                sex: userInfo.sex || undefined,
                layout_card_slug: store.getState().client.identity?.slug || '',
            },
            {
                responseType: isIOS ? 'blob' : 'json',
                headers: {
                    Authorization: token,
                    Subdomain: store.getState().client.identity?.subdomain || '',
                },
            },
        );

        if (data instanceof Blob) return data;
        else if (data.apple_card) return data.apple_card;

        return null;
    },

    async getCofixCompanyData(): Promise<CofixCompanyDto> {
        const {data} = await httpClient.get(`${getCurrentLanguage()}/api/v01/company/me/`);

        return data;
    },

    async getCofixUserInfo(phone: string, token: string): Promise<UserInfoDto> {
        const {data} = await httpClient.get(`${getCurrentLanguage()}/api/v01/sailplay/proxy/get-user-info/${phone}/`, {
            headers: {
                Authorization: token,
            },
        });

        return data;
    },

    async getUserInfo(phone: string, token: string): Promise<UserInfoDto> {
        const {data} = await httpClient.get(`${getCurrentLanguage()}/api/v01/sailplay/proxy/get-user-info/${phone}/`, {
            headers: {
                Authorization: token,
                Subdomain: store.getState().client.identity?.subdomain || '',
            },
        });

        return data;
    },
};

export default ClientAPI;
