import cn from 'classnames';

import styles from './CofixSpinner.module.scss';

type Props = {
  loading: boolean;
  className?: string;
};

export default function CofixSpinner(props: Props) {
  const { loading, className } = props;

  return (
    <svg
      className={cn(styles.spinner, className)}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={cn({ [styles.spinnerCircleOut]: !loading })} cx="10" cy="10" r="10" fill="#772B21" />
      <circle className={cn({ [styles.spinnerCircleOut]: !loading })} cx="10" cy="30" r="10" fill="#8A80CE" />
      <circle className={cn({ [styles.spinnerCircleOut]: !loading })} cx="30" cy="10" r="10" fill="#8FC056" />
      <circle className={cn({ [styles.spinnerCircleOut]: !loading })} cx="30" cy="30" r="10" fill="#F05633" />
    </svg>
  );
}
