import { FormEvent, ReactElement, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './AuthPage.module.scss';

import { AppBottomSheet, AppButton } from '@/components/app';
import { emptyFromSpecSymbols } from '@/utils/formatters';
import { ruPhoneRegEx } from '@/utils/regexp';
import { Paths } from '@/router';
import { AuthService } from '@/services';
import { IdentityLabel } from '@/components';
import { RootState } from '@/store';
import CofixErrorPopup, { ErrorPopupCfg } from '@/pages/cofix/computed/CofixErrorPopup';

export default function AuthPage(): ReactElement {
  const navigate = useNavigate();
  const { subdomain } = useParams();

  //
  // State
  //

  const [processing, setProcessing] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [docsPopupOpened, setDocsPopupOpened] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<ErrorPopupCfg>({
    open: false,
    type: '',
    message: '',
    btnType: 'close',
  });

  //
  // Store
  //

  const { identity } = useSelector((state: RootState) => state.client);
  const { i18 } = useSelector((state: RootState) => state.i18n);

  //
  // Computed
  //

  const purePhone = useMemo(() => emptyFromSpecSymbols(phone), [phone]);
  const isPhoneValid = useMemo(() => ruPhoneRegEx.test(purePhone), [purePhone]);

  //
  // Methods
  //

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isPhoneValid) return;

    sendCode();
  };

  const sendCode = async () => {
    setProcessing(true);

    try {
      await AuthService.getSmsCode(purePhone);
      navigate(`/${subdomain}/${Paths.confirmCode}?slug=${identity?.slug}`, { state: { deformatPhone: purePhone, phone } });
    } catch (error: any) {
      if (!error?.data) navigate(`/${subdomain}/${Paths.error}?code=unknown`);
      navigate(`/${subdomain}/${Paths.error}?slug=${identity?.slug}&code=${error.data.error_code}`);
    } finally {
      setProcessing(false);
    }
  };

    const renderDocIcon = (
        <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                    d='M13 16H0V0H9.67166L12.9608 3.56673L13 16ZM1.95783 13.9849H11.0226V4.35265L8.82981 1.99494H1.95783V13.9849Z'
                    fill='#181818'
            />
            <path d='M12.0994 5.05794H8.24246V1.08816L12.0994 5.05794Z' fill='#181818' />
        </svg>
    );

  //
  // Render
  //

  return (
    <section className={styles.host}>
      <IdentityLabel className={styles.identity} />

      <form onSubmit={handleSubmit}>
        <h2>{i18?.auth_registration}</h2>
        <p>
            {i18?.auth_your_phone}
        </p>

        <InputMask
          alwaysShowMask={false}
          disabled={processing}
          maskChar=" "
          mask="+7 999 999 99 99"
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
          placeholder="+7 "
          type="tel"
          value={phone}
        />

        <p className={styles.docs}>
            {i18?.auth_accepting_toc} <span onClick={() => setDocsPopupOpened(true)}>{i18?.auth_toc}</span>
        </p>

        <AppButton disabled={processing || !isPhoneValid} type="submit">
            {i18?.auth_login}
        </AppButton>
      </form>

        <AppBottomSheet
                adjustHeight
                isOpen={docsPopupOpened}
                onChangeState={(open) => setDocsPopupOpened(open)}
                maxHeight={340}
        >
            <div className={styles.docsPopup}>
                {i18?.documents?.map((document) => (
                        <a
                                key={document.link}
                                href={document.link}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={styles.docRow}
                        >
                            {renderDocIcon}
                            {document.name_link}
                        </a>
                ))}

                <p>{i18?.auth_content_of_toc}</p>
            </div>
        </AppBottomSheet>

        <CofixErrorPopup
                btnAction={errorPopup.btnType}
                message={errorPopup.message}
                onChange={(open, message, type, btnType) => setErrorPopup({ open, message, type, btnType })}
                open={errorPopup.open}
                type={errorPopup.type}
        />

    </section>
  );
}
