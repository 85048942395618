import { ReactElement, useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import styles from './AddCardPage.module.scss';
import applewallet from '@/assets/img/applewallet.svg';
import googlepay from '@/assets/img/googlepay.svg';

import { IdentityLabel } from '@/components';
import { RootState, useAppDispatch } from '@/store';
import { ClientService } from '@/services';
import { ClientAPI } from '@/api/sources';
import { setCardAssets } from '@/store/clientSlice';
import AppCircularSpinner from '@/components/app/AppCircularSpinner/AppCircularSpinner';

export default function AddCardPage(): ReactElement {
  const deviceName = isIOS ? 'iPhone' : 'телефон';
  const buttonName = isIOS ? '“Добавить в Apple Wallet”' : '“Сохранить на телефоне”';
  const navigate = useNavigate();
  const location = useLocation();
  const { subdomain } = useParams();
  const { i18 } = useSelector((state: RootState) => state.i18n);

  //
  // State
  //

  const [loading, setLoading] = useState(false);

  //
  // Store
  //

  const dispatch = useAppDispatch();
  const { cardAssets, identity } = useSelector((state: RootState) => state.client);

  //
  // Methods
  //

  const handleCardClick = () => {
    if (!cardAssets) {
        return null;
    }

    if (cardAssets instanceof Blob) {
        saveAs(cardAssets, `${identity?.name} card`);
    }

    else {
        window.open(cardAssets);
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    async function fetchCachedCard() {
      const cacheTmpUserInfo = ClientService.getCacheTmpUserInfo();

      if (!cacheTmpUserInfo) {
        navigate(`/${subdomain}${location.search}`);
        return;
      }

      if (cardAssets) {
        return;
      }

      try {
        setLoading(true);
        const { phone, token } = cacheTmpUserInfo;
        const userInfo = await ClientAPI.getUserInfo(phone, token);
        const cardAssets = await ClientAPI.getWalletAssets(phone, userInfo, token);
        dispatch(setCardAssets(cardAssets));
      } catch (error) {
        navigate(`/${subdomain}${location.search}`);
      } finally {
        setLoading(false);
      }
    }
    fetchCachedCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Render
  //

  const renderButton = isIOS ? (
    <img onClick={handleCardClick} src={applewallet} alt="apple wallet" />
  ) : (
    <img onClick={handleCardClick} src={googlepay} alt="google pay" />
  );

  return (
    <section className={styles.host}>
      <IdentityLabel className={styles.identity} />

      <section className={styles.content}>
        <h2>{i18?.add_card_header}</h2>
        <p>
            {i18?.add_card_almost_ready}
        </p>

        <p className={styles.help}>
            {i18?.add_card_to_add_card}
          <br />
          {deviceName}, {i18?.add_card_press_the_button}
          <br />
          {buttonName}
        </p>

        {cardAssets ? renderButton : loading ? <AppCircularSpinner radius={30} stroke={2} /> : i18?.errors_smth_went_wrong}
      </section>
    </section>
  );
}
