import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { clientReducer } from './clientSlice';
import { i18Reducer } from './i18nSlice';

export const store = configureStore({
  reducer: {
    client: clientReducer,
    i18n: i18Reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
