import { ReactElement } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AddCardPage, AuthPage, ConfirmCodePage, ErrorPage, FormPage, NotFoundPage } from '@/pages';
import Paths from './paths';
import { AppLayout, CofixLayout } from '@/layout';
import CofixAuthPage from '@/pages/cofix/CofixAuthPage';
import CofixConfirmCodePage from '@/pages/cofix/CofixConfirmCodePage';
import CofixFormPage from '@/pages/cofix/CofixFormPage';
import CofixAddCardPage from '@/pages/cofix/CofixAddCardPage';
import { AppFlavor } from '@/flavors';
import { CofixFlavor } from '@/flavors/apps';
import CofixChangeLanguage from '@/pages/cofix/CofixChangeLanguage';

export default function AppRouter(): ReactElement {
    const renderRouterCfg = () => {
        const currentLanguage = window.localStorage.getItem('currentLanguage');

        if (AppFlavor.flavorModule instanceof CofixFlavor) {
            return (
                    <Route path={Paths.rootCofix} element={<CofixLayout />}>
                        <Route index element={currentLanguage ? <CofixAuthPage /> : <CofixChangeLanguage />} />
                        <Route path={Paths.confirmCode} element={<CofixConfirmCodePage />} />
                        <Route path={Paths.form} element={<CofixFormPage />} />
                        <Route path={Paths.addCard} element={<CofixAddCardPage />} />
                    </Route>
            );
        }

        return (
                <Route path={Paths.auth} element={<AppLayout />}>
                    <Route index element={currentLanguage ? <AuthPage /> : <CofixChangeLanguage />} />
                    <Route path={Paths.confirmCode} element={<ConfirmCodePage />} />
                    <Route path={Paths.form} element={<FormPage />} />
                    <Route path={Paths.addCard} element={<AddCardPage />} />
                    <Route path={Paths.error} element={<ErrorPage />} />
                </Route>
        );
    };

    return (
            <>
                <BrowserRouter>
                    <Routes>
                        {renderRouterCfg()}
                        <Route path={Paths.notFound} element={<NotFoundPage />} />
                        <Route path='*' element={<NotFoundPage />} />
                    </Routes>
                </BrowserRouter>
            </>
    );
}
