import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import styles from './IdentityLabel.module.scss';

import { RootState } from '@/store';

interface Props {
  className?: string;
}

export default function IdentityLabel({ className = '' }: Props): ReactElement | null {
  //
  // Store
  //

  const { identity } = useSelector((state: RootState) => state.client);

  //
  // Render
  //

  if (!identity) return null;

  return (
    <section className={cn(styles.host, className)}>
      {identity.logo && <img src={identity.logo} alt="Логотип" />}
      <h2>{identity.name}</h2>
    </section>
  );
}
