import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import cn from 'classnames';

import styles from './CofixFormPage.module.scss';

import { AppButton } from '@/components/app';
import CofixErrorPopup, { ErrorPopupCfg } from '../computed/CofixErrorPopup';
import { GatewayTimeout, InternalServerError, ServerUnavailable } from '@/api/exceptions';
import { Paths } from '@/router';
import { ClientAPI } from '@/api/sources';
import { RootState, useAppDispatch } from '@/store';
import { setCardAssets } from '@/store/clientSlice';
import { ClientService } from '@/services';
import { useSelector } from 'react-redux';

type FormFields = {
    name: string;
    surname: string;
    day: string;
    month: string;
    year: string;
    gender: 'male' | 'female' | 'other' | undefined;
};

export default function CofixFormPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const routerState = location.state as { token: string; deformatPhone: string };
    const { i18 } = useSelector((state: RootState) => state.i18n);

    //
    // Computed
    //

    const token = routerState.token ?? '';
    const deformatPhone = routerState.deformatPhone ?? '';

    const monthRef = useRef<HTMLInputElement>(null);
    const yearRef = useRef<HTMLInputElement>(null);

    const validationSchema = yup.object().shape({
        name: yup.string(),
        surname: yup.string(),
        day: yup.string(),
        month: yup.string(),
        year: yup.string(),
        gender: yup.string(),
    });

    //
    // Store
    //

    const dispatch = useAppDispatch();

    //
    // Methods
    //

    const handleSubmit = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
        setProcessing(true);

        try {
            const genderToDto = {
                male: 1,
                female: 2,
                other: 3,
            };
            const cardAssets = await ClientAPI.getCofixWalletAssets(
                    deformatPhone,
                    {
                        birth_date: values.day && values.month && values.year ? `${values.year}-${values.month}-${values.day}` : null,
                        first_name: values.name || null,
                        last_name: values.surname || null,
                        sex: values.gender ? genderToDto[values.gender] : null,
                        user_phone: deformatPhone,
                    },
                    token,
            );
            ClientService.setCacheTmpUserInfo({ phone: deformatPhone, token });
            dispatch(setCardAssets(cardAssets));
            navigate(`/${Paths.addCard}`);
        } catch (error: any) {
            const errorPopupCfg: ErrorPopupCfg = {
                open: true,
                type: i18?.errors_error ?? '',
                message: i18?.errors_smth_went_wrong ?? '',
                btnType: 'close',
            };

            if (
                    !navigator.onLine ||
                    error instanceof ServerUnavailable ||
                    error instanceof InternalServerError ||
                    error instanceof GatewayTimeout
            ) {
                errorPopupCfg.type = i18?.errors_connection_error ?? '';
                errorPopupCfg.message = i18?.errors_try_later ?? '';
                errorPopupCfg.btnType = 'close';
            }

            setErrorPopup(errorPopupCfg);
            throw error;
        } finally {
            setProcessing(false);
        }
    };

    const formik = useFormik<FormFields>({
        initialValues: {
            name: '',
            surname: '',
            day: '',
            month: '',
            year: '',
            gender: undefined,
        },
        onSubmit: (values, { setErrors }) => {
            handleSubmit(values, setErrors);
        },
        validationSchema,
    });

    //
    // State
    //

    const [processing, setProcessing] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<ErrorPopupCfg>({
        open: false,
        type: '',
        message: '',
        btnType: 'close',
    });

    //
    // Computed
    //

    const yearValid = useMemo(() => {
        if (!formik.values.year && !formik.values.day && !formik.values.month) return true;

        const year = parseInt(formik.values.year);
        const nowYear = new Date().getFullYear();

        if (year > nowYear) return false;

        return nowYear - year >= 16 && nowYear - year <= 100;
    }, [formik.values.day, formik.values.month, formik.values.year]);

    const dayValid = useMemo(() => {
        if (!formik.values.year && !formik.values.day && !formik.values.month) return true;

        const day = parseInt(formik.values.day);

        return day >= 1 && day <= 31;
    }, [formik.values.day, formik.values.month, formik.values.year]);

    const monthValid = useMemo(() => {
        if (!formik.values.year && !formik.values.day && !formik.values.month) return true;

        const month = parseInt(formik.values.month);

        return month >= 1 && month <= 12;
    }, [formik.values.day, formik.values.month, formik.values.year]);

    //
    // Methods
    //

    //
    // Effects
    //

    useEffect(() => {
        if (formik.values.day.length >= 2) {
            monthRef.current?.focus();
        }
    }, [formik.values.day]);

    useEffect(() => {
        if (formik.values.month.length >= 2) {
            yearRef.current?.focus();
        }
    }, [formik.values.month]);

    useEffect(() => {
        if (formik.values.year.length >= 4) {
            yearRef.current?.blur();
        }
    }, [formik.values.year]);

    //
    // Render
    //

    return (
            <section className={cn(styles.host, 'fadeIn')}>
                <header>
                    <svg
                            onClick={() => navigate(-2)}
                            width='12'
                            height='21'
                            viewBox='0 0 12 21'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M12 1.4L10.5882 0L1.4118 9.10003L1.41175 9.09999L0 10.5L10.5881 21L11.9999 19.6L2.82355 10.5L12 1.4Z'
                                fill='white'
                        />
                    </svg>
                    <h1>{i18?.form_header}</h1>
                    <span />
                </header>

                <form className={styles.host} onSubmit={formik.handleSubmit}>
                    <div className={styles.field}>
                        <label>
                            {i18?.form_name}
                        </label>
                        <input
                                className={cn({ [styles.fieldInvalid]: !!formik.errors.name })}
                                name='name'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                        />
                        {formik.errors.name && <span className={cn(styles.fieldError, 'fadeIn')}>{formik.errors.name}</span>}
                    </div>

                    <div className={styles.field}>
                        <label>{i18?.form_surname}</label>
                        <input
                                name='surname'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.surname}
                        />
                    </div>

                    <div className={styles.dateFieldSet}>
                        <label>{i18?.form_birthdate}</label>
                        <div>
                            <input
                                    type='tel'
                                    placeholder={i18?.form_dd}
                                    name='day'
                                    onBlur={(e) => {
                                        const value = e.target.value;

                                        if (value && value.length === 1 && parseInt(value) < 10) formik.setFieldValue('day', '0' + value);

                                        return formik.handleBlur;
                                    }}
                                    onChange={formik.handleChange}
                                    value={formik.values.day}
                                    maxLength={2}
                                    className={cn({ [styles.fieldInvalid]: !dayValid })}
                            />
                            <input
                                    placeholder={i18?.form_mm}
                                    name='month'
                                    onBlur={(e) => {
                                        const value = e.target.value;

                                        if (value && value.length === 1 && parseInt(value) < 10) formik.setFieldValue('month', '0' + value);

                                        return formik.handleBlur;
                                    }}
                                    onChange={formik.handleChange}
                                    value={formik.values.month}
                                    type='tel'
                                    maxLength={2}
                                    ref={monthRef}
                                    className={cn({ [styles.fieldInvalid]: !monthValid })}
                            />
                            <input
                                    placeholder={i18?.form_yyyy}
                                    name='year'
                                    onBlur={(e) => {
                                        setTimeout(() => {
                                            const value = parseInt(e.target.value);
                                            const nowYear = new Date().getFullYear();

                                            if (nowYear - value < 16 && nowYear - value >= 0)
                                                formik.setFieldError('year', i18?.errors_over_years_old);
                                        }, 100);

                                        return formik.handleBlur;
                                    }}
                                    onChange={formik.handleChange}
                                    value={formik.values.year}
                                    type='tel'
                                    maxLength={4}
                                    ref={yearRef}
                                    className={cn({ [styles.fieldInvalid]: !yearValid })}
                            />
                        </div>

                        {(!yearValid || !dayValid || !monthValid) && (
                                <span className={styles.fieldError}>{formik.errors.year || i18?.errors_incorrect}</span>
                        )}
                    </div>

                    <div className={styles.genderFieldSet}>
                        <label>{i18?.form_gender}</label>
                        <div>
                            <div
                                    onClick={() => formik.setFieldValue('gender', 'male')}
                                    className={cn(styles.genderField, { [styles.genderFieldActive]: formik.values.gender === 'male' })}
                            >
                                {i18?.form_male}
                            </div>
                            <div
                                    onClick={() => formik.setFieldValue('gender', 'female')}
                                    className={cn(styles.genderField, { [styles.genderFieldActive]: formik.values.gender === 'female' })}
                            >
                                {i18?.form_female}
                            </div>
                            <div
                                    onClick={() => formik.setFieldValue('gender', 'other')}
                                    className={cn(styles.genderField, { [styles.genderFieldActive]: formik.values.gender === 'other' })}
                            >
                                {i18?.form_other}
                            </div>
                        </div>
                    </div>

                    <AppButton
                            disabled={processing}
                            className={styles.submitBtn}
                            type='submit'
                    >
                        {i18?.form_save}
                    </AppButton>
                </form>

                <CofixErrorPopup
                        btnAction={errorPopup.btnType}
                        message={errorPopup.message}
                        onChange={(open, message, type, btnType) => setErrorPopup({ open, message, type, btnType })}
                        open={errorPopup.open}
                        type={errorPopup.type}
                />
            </section>
    );
}
