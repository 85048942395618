import styles from './NotFoundPage.module.scss';

export default function NotFoundPage() {
  return (
    <div className={styles.host}>
      <h1>404</h1>
      <p>Страница, которую вы запрашиваете, не существует. <br /> Возможно она была удалена, или вы набрали неверный адрес.</p>
    </div>
  );
}
