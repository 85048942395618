import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClientIdentity, CofixCompany } from '@/models';

type SliceState = {
  loading: boolean;
  error: boolean;
  identity: ClientIdentity | null;
  cardAssets: string | Blob | null;
  company: CofixCompany | null;
};

const initialState: SliceState = {
  loading: true,
  error: false,
  identity: null,
  cardAssets: null,
  company: null,
};

const client = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setIdentity: (state, action: PayloadAction<ClientIdentity | null>) => {
      state.identity = action.payload;
    },
    setCardAssets: (state, action: PayloadAction<string | Blob | null>) => {
      state.cardAssets = action.payload;
    },
    setCompany: (state, action: PayloadAction<CofixCompany | null>) => {
      state.company = action.payload;
    },
  },
});

export const { reducer: clientReducer } = client;
export const { setLoading, setError, setIdentity, setCardAssets, setCompany } = client.actions;
