import { FormEvent, ReactElement, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import styles from './CofixAuthPage.module.scss';

import { AppBottomSheet, AppButton } from '@/components/app';
import { emptyFromSpecSymbols } from '@/utils/formatters';
import { Paths } from '@/router';
import { AuthService, ClientService } from '@/services';
import wellKnownErrors from '@/api/wellKnownErrors';
import { ServerUnavailable, InternalServerError, GatewayTimeout } from '@/api/exceptions';
import CofixErrorPopup, { ErrorPopupCfg } from '../computed/CofixErrorPopup';
import { RootState } from '@/store';
import { CofixCompanyLanguage, Country } from '@/models/CofixCompany';
import { CountryPickerPopup } from '@/components';

export default function CofixAuthPage(): ReactElement {
    const navigate = useNavigate();

    const { i18 } = useSelector((state: RootState) => state.i18n);

    //
    // State
    //

    const [processing, setProcessing] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>('');
    const [phoneMaskCountry, setPhoneMaskCountry] = useState<Country | null>();
    const [countryPickerOpened, setCountryPickerOpened] = useState<boolean>(false);
    const [phoneFieldRef, setPhoneFieldRef] = useState<HTMLInputElement | null>(null);
    const [docsPopupOpened, setDocsPopupOpened] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<ErrorPopupCfg>({
        open: false,
        type: '',
        message: '',
        btnType: 'close',
    });

    //
    // Store
    //

    const { company } = useSelector((state: RootState) => state.client);

    //
    // Computed
    //

    const phoneMask = phoneMaskCountry?.maskPhone;
    const countryFlagImg = phoneMaskCountry?.flag;
    const countryName = phoneMaskCountry?.name;
    const isPhoneValid = emptyFromSpecSymbols(phone).length === emptyFromSpecSymbols(phoneMask || '').length;

    //
    // Methods
    //

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        sendCode();
    };

    const isCountryActive = (country: Country | CofixCompanyLanguage) => {
        return phoneMaskCountry?.code === country.code;
    };

    const handleCountryClick = (country: Country | CofixCompanyLanguage)=>{
        setPhoneMaskCountry(country as Country);
        setPhone('');
        setCountryPickerOpened(false);
    }

    const handleGoBack = () => {
        if(window) {
            window.localStorage.removeItem('currentLanguage');
            window.location.reload();
        }
    };

    const sendCode = async () => {
        if (!phoneMaskCountry) return;

        setProcessing(true);

        try {
            const deformatPhone = emptyFromSpecSymbols(phone);
            await AuthService.getCofixSmsCode(deformatPhone);
            navigate(`/${Paths.confirmCode}`, { state: { deformatPhone, phone } });
        } catch (error: any) {
            const errorPopupCfg: ErrorPopupCfg = {
                open: true,
                type: i18?.errors_error ?? '',
                message: i18?.errors_smth_went_wrong ?? '',
                btnType: 'refresh',
            };

            if (
                    !navigator.onLine ||
                    error instanceof ServerUnavailable ||
                    error instanceof InternalServerError ||
                    error instanceof GatewayTimeout
            ) {
                errorPopupCfg.type = i18?.errors_connection_error ?? '';
                errorPopupCfg.message = i18?.errors_try_later ?? '';
                errorPopupCfg.btnType = 'refresh';
            } else if (error.data.error_code.includes(wellKnownErrors.validatePhoneError)) {
                errorPopupCfg.type = i18?.errors_input_error ?? '';
                errorPopupCfg.message = i18?.errors_number_incorrect ?? '';
                errorPopupCfg.btnType = 'close';
            } else if (
                    error.data.error_code.includes(wellKnownErrors.limit15MinutesRequestError) ||
                    error.data.error_code.includes(wellKnownErrors.limitDayRequestError)
            ) {
                errorPopupCfg.type = i18?.errors_error ?? '';
                errorPopupCfg.message = i18?.errors_code_request_limit_exceeded ?? '';
                errorPopupCfg.btnType = 'close';
            }

            setErrorPopup(errorPopupCfg);
            throw error;
        } finally {
            setProcessing(false);
        }
    };

    //
    // Effects
    //

    useEffect(() => {
        ClientService.removeCacheTmpUserInfo();
    }, []);

    useEffect(() => {
        if (company?.countries[0]) {
            setPhoneMaskCountry(company.countries[0]);
        }
    }, [company]);

    useEffect(() => {
        if (isPhoneValid && phoneFieldRef) {
            phoneFieldRef.blur();
        }
    }, [isPhoneValid, phoneFieldRef]);

    //
    // Render
    //

    const renderDocIcon = (
            <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                        d='M13 16H0V0H9.67166L12.9608 3.56673L13 16ZM1.95783 13.9849H11.0226V4.35265L8.82981 1.99494H1.95783V13.9849Z'
                        fill='#181818'
                />
                <path d='M12.0994 5.05794H8.24246V1.08816L12.0994 5.05794Z' fill='#181818' />
            </svg>
    );

    return (
            <>
                <section className={cn(styles.host, 'fadeIn')}>
                    <header>
                        <svg
                                onClick={handleGoBack}
                                width='12'
                                height='21'
                                viewBox='0 0 12 21'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M12 1.4L10.5882 0L1.4118 9.10003L1.41175 9.09999L0 10.5L10.5881 21L11.9999 19.6L2.82355 10.5L12 1.4Z'
                                    fill='white'
                            />
                        </svg>
                        <h1>{i18?.auth_registration}</h1>
                    </header>

                    <form onSubmit={handleSubmit}>
                        <label>
                            <span>{i18?.auth_your_phone}</span>
                            <InputMask
                                    inputRef={(ref) => setPhoneFieldRef(ref)}
                                    alwaysShowMask
                                    disabled={processing}
                                    maskChar=' '
                                    mask={phoneMask || ''}
                                    name='phone'
                                    onChange={(e) => setPhone(e.target.value)}
                                    type='tel'
                                    value={phone}
                            />
                        </label>

                        {company?.countries && company.countries.length > 1 && (
                                <div onClick={() => setCountryPickerOpened(true)} className={styles.countryPicker}>
                                    {countryFlagImg && <img src={countryFlagImg} alt='country flag' />}
                                    <svg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path d='M4 4L0.535898 0.25L7.4641 0.25L4 4Z' fill='white' />
                                    </svg>
                                    <span>{countryName}</span>
                                </div>
                        )}

                        <p className={styles.docs}>
                            {i18?.auth_accepting_toc} <span onClick={() => setDocsPopupOpened(true)}>{i18?.auth_toc}</span>
                        </p>

                        <AppButton className={styles.submitBtn} disabled={processing || !isPhoneValid} type='submit'>
                            {i18?.auth_login}
                        </AppButton>
                    </form>

                    <AppBottomSheet
                            maxHeight={400}
                            isOpen={countryPickerOpened}
                            onChangeState={(open) => setCountryPickerOpened(open)}
                            adjustHeight
                    >
                        <CountryPickerPopup countries={company?.countries} checkIsActive={isCountryActive} onClick={handleCountryClick} />
                    </AppBottomSheet>

                    <AppBottomSheet
                            adjustHeight
                            isOpen={docsPopupOpened}
                            onChangeState={(open) => setDocsPopupOpened(open)}
                            maxHeight={340}
                    >
                        <div className={styles.docsPopup}>
                            {i18?.documents?.map((document) => (
                                    <a
                                            key={document.link}
                                            href={document.link}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className={styles.docRow}
                                    >
                                        {renderDocIcon}
                                        {document.name_link}
                                    </a>
                            ))}

                            <p>{i18?.auth_content_of_toc}</p>
                        </div>
                    </AppBottomSheet>

                    <CofixErrorPopup
                            btnAction={errorPopup.btnType}
                            message={errorPopup.message}
                            onChange={(open, message, type, btnType) => setErrorPopup({ open, message, type, btnType })}
                            open={errorPopup.open}
                            type={errorPopup.type}
                    />
                </section>
            </>
    );
}
