import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClientIdentity } from '@/models';

type SliceState = {
    i18: ClientIdentity | null;
};

const initialState: SliceState = {
    i18: null
};

const i18 = createSlice({
    name: 'i18',
    initialState,
    reducers: {
        setI18: (state, action: PayloadAction<ClientIdentity | null>) => {
            state.i18 = action.payload;
        },
    },
});

export const { reducer: i18Reducer } = i18;
export const { setI18 } = i18.actions;
