import { Slide, toast } from 'react-toastify';
import { isDesktop } from 'react-device-detect';

export const notifyApp = (
  message: string,
  type: 'info' | 'success' | 'warning' | 'error' | 'default',
  timeout: number = 2000
) => {
  toast(message, {
    autoClose: timeout,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: !isDesktop,
    progress: undefined,
    type,
    transition: Slide,
  });
};
