import { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import styles from './CofixLayout.module.scss';

import CofixErrorPopup, { ErrorPopupCfg } from '@/pages/cofix/computed/CofixErrorPopup';
import CofixPreloader from './CofixPreloader';
import { RootState, useAppDispatch } from '@/store';
import { setI18 } from '@/store/i18nSlice';
import { ClientService } from '@/services';
import { setCompany } from '@/store/clientSlice';
import { useSelector } from 'react-redux';

export default function CofixLayout(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18 } = useSelector((state: RootState) => state.i18n);

  //
  // State
  //

  const [loading, setLoading] = useState<boolean>(true);
  const [errorPopup, setErrorPopup] = useState<ErrorPopupCfg>({
    open: false,
    type: i18?.errors_connection_error ?? '',
    message: i18?.errors_try_later ?? '',
    btnType: 'refresh',
  });

  //
  // Store
  //

  const dispatch = useAppDispatch();

  //
  // Methods
  //

  const loadClientData = async () => {
    try {
      const company = await ClientService.getCofixCompanyData();
      const locale = await ClientService.getClientIdentity('cofix','');

      dispatch(setCompany(company));
      dispatch(setI18(locale));

      setLoading(false);
    } catch (error) {
      setErrorPopup({
        open: true,
        type: i18?.errors_connection_error ?? '',
        message: i18?.errors_try_later ?? '',
        btnType: 'refresh',
      });

      throw error;
    }
  };

  //
  // Effects
  //

  // Navigate initially to the first page
  useEffect(() => {
    if (location.pathname.includes('card') && ClientService.getCacheTmpUserInfo()) {
      return;
    }

    navigate(`/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const performMobileViewportHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    performMobileViewportHeight();

    window.addEventListener('resize', performMobileViewportHeight, { passive: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/static/cofix/favicon.ico';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Render
  //

  return (
    <section className={styles.host}>
      <main>
        <CofixPreloader loading={loading} />
        <CofixErrorPopup
          btnAction={errorPopup.btnType}
          message={errorPopup.message}
          onChange={(open, message, type, btnType) => setErrorPopup({ open, message, type, btnType })}
          open={errorPopup.open}
          type={errorPopup.type}
        />

        <Outlet />
      </main>
    </section>
  );
}
