import styles from './CofixErrorPopup.module.scss';

import { AppBottomSheet, AppButton } from '@/components/app';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

type Props = {
    open: boolean;
    message: string;
    type: string;
    btnAction: 'close' | 'refresh';
    onChange: (open: boolean, message: string, type: string, btnAction: 'close' | 'refresh') => void;
};

export default function CofixErrorPopup(props: Props) {
    const { open, onChange, btnAction, message, type } = props;
    const { i18 } = useSelector((state: RootState) => state.i18n);

    return (
            <AppBottomSheet
                    adjustHeight
                    isOpen={open}
                    onChangeState={(open) => onChange(open, message, type, btnAction)}
                    maxHeight={340}
            >
                <div className={styles.host}>
                    <h3>{type}</h3>
                    <h2>{message}</h2>
                    <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                                d='M60.0202 3.39572e-06C48.1525 -0.00398844 36.5501 3.51156 26.6805 10.102C16.8109 16.6925 9.11744 26.0618 4.57308 37.025C0.0287344 47.9882 -1.1624 60.0529 1.15044 71.693C3.46327 83.3332 9.17614 94.0261 17.5665 102.419C25.9568 110.812 36.6478 116.529 48.2872 118.846C59.9266 121.162 71.9916 119.975 82.9564 115.435C93.9211 110.894 103.293 103.204 109.887 93.3363C116.481 83.4689 120 71.8677 120 60C119.979 44.0971 113.653 28.8513 102.41 17.6043C91.1667 6.35733 75.9231 0.0266988 60.0202 3.39572e-06ZM60.0202 113.925C49.3491 113.929 38.9165 110.768 30.0421 104.842C21.1677 98.9162 14.2502 90.4913 10.1647 80.6332C6.07922 70.7751 5.00921 59.9268 7.09006 49.4605C9.17092 38.9942 14.3091 29.3802 21.8548 21.8346C29.4004 14.289 39.0144 9.15074 49.4807 7.06988C59.947 4.98902 70.7953 6.05902 80.6534 10.1445C90.5115 14.23 98.9363 21.1475 104.862 30.0219C110.788 38.8963 113.95 49.3289 113.946 60C113.929 74.297 108.243 88.0038 98.1334 98.1133C88.0239 108.223 74.3172 113.909 60.0202 113.925Z'
                                fill='#181818'
                        />
                        <path
                                d='M82.3007 20.1211H37.7599L36.1655 28.9808H29.9092V43.8749H34.5913L40.9687 102.281H79.0918L85.4692 43.8749H90.1513V28.9808H83.9556L82.3007 20.1211ZM42.7851 26.1756H77.2755L77.78 28.9606H42.22L42.7851 26.1756ZM84.0969 37.78H35.9637V35.0353H84.0969V37.78ZM73.6629 96.3068H46.3976L40.6862 43.9556H79.3744L73.6629 96.3068Z'
                                fill='#181818'
                        />
                        <path
                                d='M45.9939 73.0979H52.0484C52.3374 71.1879 53.3017 69.4448 54.7662 68.1851C56.2308 66.9254 58.0985 66.2327 60.0303 66.2327C61.9621 66.2327 63.8297 66.9254 65.2943 68.1851C66.7588 69.4448 67.7232 71.1879 68.0121 73.0979H74.0666C73.6738 69.6504 72.026 66.468 69.4373 64.1575C66.8486 61.847 63.5001 60.57 60.0303 60.57C56.5605 60.57 53.212 61.847 50.6233 64.1575C48.0346 66.468 46.3867 69.6504 45.9939 73.0979Z'
                                fill='#181818'
                        />
                        <path
                                d='M52.3915 56.2866C54.0857 56.2866 55.4591 54.9132 55.4591 53.219C55.4591 51.5248 54.0857 50.1514 52.3915 50.1514C50.6973 50.1514 49.3239 51.5248 49.3239 53.219C49.3239 54.9132 50.6973 56.2866 52.3915 56.2866Z'
                                fill='#181818'
                        />
                        <path
                                d='M67.669 56.2866C69.3632 56.2866 70.7366 54.9132 70.7366 53.219C70.7366 51.5248 69.3632 50.1514 67.669 50.1514C65.9748 50.1514 64.6014 51.5248 64.6014 53.219C64.6014 54.9132 65.9748 56.2866 67.669 56.2866Z'
                                fill='#181818'
                        />
                    </svg>
                    <AppButton
                            onClick={() => {
                                if (btnAction === 'refresh') {
                                    window.location.reload();
                                } else {
                                    onChange(false, message, type, btnAction);
                                }
                            }}
                            type='button'
                    >
                        {btnAction === 'close' ? i18?.common_accept : i18?.common_refresh}
                    </AppButton>
                </div>
            </AppBottomSheet>
    );
}
