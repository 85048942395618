import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './ConfirmCodePage.module.scss';
import { formatPhone } from '@/utils/formatters';
import { IdentityLabel } from '@/components';
import { useCountdown } from '@/hooks';
import { AppButton } from '@/components/app';
import { AuthService, ClientService } from '@/services';
import { Paths } from '@/router';
import { notifyApp } from '@/utils/notifications';
import { RootState, useAppDispatch } from '@/store';
import { setCardAssets } from '@/store/clientSlice';
import { AuthAPI, ClientAPI } from '@/api/sources';
import { NotFoundError } from '@/api/exceptions';

export default function ConfirmCodePage(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { subdomain } = useParams();
  const routerState = location.state as { phone: string; deformatPhone: string };
  const [countdown, { start: launchCountdown }] = useCountdown(30 * 1000);
  const deformatPhone = routerState.deformatPhone ?? '';
  const { i18 } = useSelector((state: RootState) => state.i18n);

  //
  // State
  //

  const [code, setCode] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);

  //
  // Store
  //

  const dispatch = useAppDispatch();
  const { identity } = useSelector((state: RootState) => state.client);

  //
  // Computed
  //

  const phone = routerState.phone ?? '';
  const formValid = useMemo(() => code.length === 4 && phone, [code.length, phone]);

  //
  // Methods
  //

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!formValid) return;

    let token = null;

    try {
      setProcessing(true);
      token = await AuthAPI.verifyCode(deformatPhone, code);
    } catch (error: any) {
      if (error?.data?.message) {
        notifyApp(error.data.message, 'error');
        throw error;
      }

      navigate(`/${subdomain}/${Paths.error}?code=unknown&slug=${identity?.slug}`);
      throw error;
    } finally {
      setProcessing(false);
    }

    try {
      setProcessing(true);
      const userInfo = await ClientAPI.getUserInfo(deformatPhone, token);
      ClientService.setCacheTmpUserInfo({ phone: deformatPhone, token });

      if (userInfo) {
        const cardAssets = await ClientAPI.getWalletAssets(deformatPhone, userInfo, token);
        dispatch(setCardAssets(cardAssets));
        navigate(`/${subdomain}/${Paths.addCard}?slug=${identity?.slug}`);
      }
    } catch (error: any) {
      if (error instanceof NotFoundError) {
        // if (NOT REGISTERED)
        navigate(`/${subdomain}/${Paths.form}?slug=${identity?.slug}`, { state: { deformatPhone, token } });
        return
      }

      navigate(`/${subdomain}/${Paths.error}?code=unknown&slug=${identity?.slug}`);
      throw error;
    } finally {
      setProcessing(false);
    }
  };

  const resendCode = async () => {
    setProcessing(true);

    try {
      await AuthService.getSmsCode(phone);
      launchCountdown();
    } catch (error: any) {
      if (!error?.data) navigate(`/${subdomain}/${Paths.error}?code=unknown`);
      navigate(`${Paths.error}?slug=${identity?.slug}&code=${error.data.error_code}`);
    } finally {
      setProcessing(false);
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    launchCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Render
  //

  return (
    <section className={styles.host}>
      <IdentityLabel className={styles.identity} />

      <form onSubmit={handleSubmit}>
        <p>{i18?.auth_confirm_will_call_or_sms}</p>
        <span>{formatPhone(phone)}</span>

        <input
          type="tel"
          maxLength={4}
          name="code"
          disabled={processing}
          value={code}
          autoFocus
          onChange={(e) => setCode(e.target.value)}
          placeholder={i18?.auth_confirm_last_digits}
        />

        <AppButton disabled={processing || code.length !== 4} type="submit">
            {i18?.auth_confirm_checking}
        </AppButton>
      </form>

      {countdown > 0 ? (
        <section className={styles.resendMessage}>
          <p className={styles.codeResendMessage}>{i18?.auth_confirm_time_remaining}</p>
          <p className={styles.timeToResend}>
            <span>{countdown / 1000}</span>
          </p>
        </section>
      ) : (
        <AppButton
          className={styles.resendCode}
          disabled={processing}
          ripple={false}
          onClick={() => {
            setCode('');
            resendCode();
          }}
          type="button"
        >
            {i18?.auth_confirm_retry_sent_code}
        </AppButton>
      )}
    </section>
  );
}
