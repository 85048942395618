import AppIds from './appIds';
import { CofixFlavor, CofixFlavorPl } from './apps';
import IFlavor from './IFlavor';

const FLAVOR_MODULES = {
  [AppIds.cofix]: CofixFlavor,
  [AppIds.cofixPl]: CofixFlavorPl,
};

class AppFlavor implements IFlavor {
  public flavorModule: IFlavor | null = null;

  constructor() {
    const appId = process.env.REACT_APP_THEME;

    console.log(`%c THEME: ${appId} `, 'background: #8B4BF1; color: #fff');

    if (!appId) {
      return;
    }

    const FlavorModule = FLAVOR_MODULES[appId];
    if (!FlavorModule) {
      console.error(`Unknown FlavorModule: ${appId}`);
      return
    }

    this.flavorModule = new FlavorModule();
  }

  public get subdomain(): string {
    return this.flavorModule?.subdomain ?? window?.location?.pathname?.slice(1) ?? '';
  }

  public get layoutCardSlug(): string {
    return this.flavorModule?.layoutCardSlug ?? '';
  }
}

export default new AppFlavor();
