import { ReactElement } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import styles from './ErrorPage.module.scss';
import denyCrestIcon from '@/assets/img/deny-crest.svg';

import wellKnownErrors from '@/api/wellKnownErrors';
import { AppButton } from '@/components/app';
import { RootState } from '@/store';

export default function ErrorPage(): ReactElement {
  const { search } = useLocation();
  const { subdomain } = useParams();
  const navigate = useNavigate();

  const { identity } = useSelector((state: RootState) => state.client);
  const { i18 } = useSelector((state: RootState) => state.i18n);

  //
  // Render
  //

  const renderMessage = () => {
    const { code } = qs.parse(search);

    if (code === wellKnownErrors.limit15MinutesRequestError)
      return i18?.errors_code_request_limit_exceeded;
    else if (code === wellKnownErrors.limitDayRequestError)
      return i18?.errors_code_request_limit_exceeded;
    else return i18?.errors_connection_error;
  };

  return (
    <section className={styles.host}>
      <img src={denyCrestIcon} alt="Иконка провала" />

      <h2>{i18?.add_card_connection_lost}</h2>
      <p>{renderMessage()}</p>
      <AppButton onClick={() => navigate(`/${subdomain}?slug=${identity?.slug}`)}>{i18?.add_card_retry}</AppButton>
    </section>
  );
}
