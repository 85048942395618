enum Paths {
  auth = ':subdomain',
  rootCofix = '/',
  confirmCode = 'code',
  addCard = 'card',
  form = 'form',
  error = 'error',
  notFound = '404',
}

export default Paths;
